import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import "./navbar.css"
const NavBar = () => {
    const navigate = useNavigate();
    const clearSession = () => {
        sessionStorage.clear()
        navigate('/')
    }
    return (
        <>
            <div className='text-end'>
                <div className="profile-Div">
                    <div class="dropdown">
                        <button class="dropbtn">
                            <i class="fas fa-user-circle" style={{ "font-size": "2.5rem", }}></i>
                        </button>
                        <div class="dropdown-content px-3">
                            <li className="email fw-medium ">{sessionStorage.getItem("email")}</li>
                            <div className="d-flex justify-content-center mb-2"><Button className="w-100" variant="secondary" onClick={clearSession}>Logout</Button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default NavBar;