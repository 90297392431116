import axios from "axios"
import { useState ,useEffect } from "react"
import { useNavigate } from "react-router-dom";
import logo from "../img/logo.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignUp = () => {
    const navigate = useNavigate();
    const [user,setUser]=useState("")
    const [password,setPassword]=useState("")
    const [email,setEmail]=useState("")
    const getSignup=()=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}signup`,{
            "password": password,
            "email": email

        }).then((res)=>{
            if(res.data.status === 200){
                console.log(res.data.message)
                toast.success(res.data.message)
                navigate(`/`);
            }
            else{
                console.log(res)
                toast.error(res.data.error)
            }
            // sessionStorage.setItem("is")
            // navigate(`/`);
        }).catch((e)=>{
            alert(e)
        })
    }
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                sessionStorage.setItem("isSignIn",true)
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [user,password,email, navigate]);
    return (
        <><div className="login-main">
            <section>
                <div className="signup-form" action="login-box">
                <div className='login-logo'><img className="login-img" src={logo} alt='logo' /></div>
                    <label className="login-label" >email</label>
                    <input className="login-input" 
                    onChange={async(event)=>{setEmail(event.target.value)}}/>
                    
                    <label className="login-label" >password</label>
                    <input className="login-input" type="password" 
                    onChange={async(event)=>{setPassword(event.target.value)}}/>
                    <button class="login-button" onClick={getSignup}>signup</button>
                    <div className="login-div">
                        <a className="login-anchor" onClick={()=>navigate('/')}>log-in</a>
                    </div>
                </div>

            </section>

        </div>
        <ToastContainer/>

        </>
    )
}
export default SignUp;