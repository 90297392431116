import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-toastify/dist/ReactToastify.css';
import Description_Modal from "./description_modal";
import Data_Loader from "../data-loader/data-loader";
const Affordable_housing = ({ address, latitude, longitude, city, state, zip }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [response, setResponse] = useState([])
    const [isAffordable, setIsAffordable] = useState(true)
    const [description, setDescription] = useState({})
    const [show, setShow] = useState(false)
    const data = () => {
        const headers = {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token")
            }
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}affordable_housing`, {
            "city": city,
            "state": state,
            "zip": zip,
            "subject_property": {
                "map_coordinates": {
                    "address": address,
                    "latitude": latitude,
                    "longitude": longitude
                }
            }
        }, headers).then(async (res) => {
            setResponse(res.data.detailed_rentals)
            setIsLoading(false)
            if (res.data.detailed_rentals !== null) {
                await axios.post(`${process.env.REACT_APP_BASE_URL}store_affordable`, {
                    "data": res.data.detailed_rentals
                }, headers).then((response) => {
                    console.log(response)
                }).catch((e) => {
                    console.log(e)
                    toast.error("failed to store affordable housing data")
                })
            }
        }).catch((e) => {
            toast.error("failed to load affordable housing data")
            setIsLoading(false)
        })
    }
    const handleAffordableDiv = () => {
        if (isAffordable === true) {
            setIsAffordable(false)
        } else {
            setIsAffordable(true)
        }
    }
    const handleHide = () => {
        setShow(false)
    }
    const handleShow = (rental_name, rental_id, rental_description, display_image,
        display_image_url, static_map, owner_name, owner_phone) => {

        let data = {
            'rental_name': rental_name, 'rental_id': rental_id, 'rental_description': rental_description,
            'display_image': display_image, 'display_image_url': display_image_url, 'static_map': static_map,
            'owner_name': owner_name, 'owner_phone': owner_phone
        }
        setShow(true)
        setDescription(data)
    }
    const numberFormat = (input) => {
        if (input) {
            if (input[0] === "$") {
                return input
            } else {
                const output = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(input);
                return output
            }

        } else {
            return "None"
        }

    }
    const capitalizeFirstCharacter = (str) => {
        if (typeof str !== 'string') {
            return str;
        }

        return str.toUpperCase();
    }
    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();

        return `${month}-${day}-${year}`;
    }
    const info = {
        headers: ['address', 'rental_type'],
        basicInfo: ['bedrooms', 'bathrooms', 'square_feet', 'unit_count', 'year_built',],
        city: ['city', 'state', 'zip',], status: ['available_date', 'listing_status',],
        links: ['url'], rent: ['distance_from_subject', 'max_rent', 'min_rent',
            ,], fees: ['deposit', 'application_fee'],
        other: ['premium_listing', 'income_restricted', 'age_restrictions', 'pets_allowed', 'deposit_negotiable', 'travel_info'],
        detailed: ['rental_name', 'rental_id', 'rental_description',
            'display_image', 'display_image_url', 'static_map', 'owner_name', 'owner_phone']
    }
    useEffect(() => {
        data()
    }, [])
    return (
        <><div>
            {isAffordable ?
                <div className="padsplit-div" onClick={handleAffordableDiv}>
                    <li className="padsplit-heading">{`Affordable Housing`}</li>
                    <i class="fas fa-angle-down"></i>
                </div> :
                <div className="padsplit-div" onClick={handleAffordableDiv}>
                    <li className="padsplit-heading">{`Affordable Housing`}</li>
                    <i class="fas fa-angle-up"></i>
                </div>}
            <Description_Modal handleShow={show} handleHide={handleHide} description={description} />
            {isAffordable ?
                (!isLoading ?
                    (response ?
                        <div className="data-container" id="scroll-bar">
                            <div className="left-data">
                                {info.headers.map((headeritem) => (
                                    <div className="pad-data-container">
                                        <li className="li-headings">
                                            {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}</li>
                                        {response.map((item) => (
                                            item[headeritem] ?
                                                <li className="li-item-right">{
                                                    headeritem === "rental_type" ?
                                                        item[headeritem].charAt(0).toUpperCase() + item[headeritem].slice(1) : item[headeritem]}</li> :
                                                <li className="li-item-right">N/A</li>
                                        ))}
                                    </div>
                                ))}
                                <div className="pad-data-container">
                                    <li className="li-headings">Description</li>
                                    {response.map((item) => (
                                        <li className="li-item-right" onClick={() =>
                                            handleShow(item['rental_name'], item['rental_id'], item['rental_description'],
                                                item['display_image'], item['display_image_url'], item['static_map'],
                                                item['owner_name'], item['owner_phone'])}><i class="fas fa-info-circle info-icon"></i>
                                        </li>
                                    ))}

                                </div>
                            </div>
                            <div className="right-data">
                                {info.basicInfo.map((headeritem) => (
                                    <div className="pad-data-container">
                                        <li className="li-headings">
                                            {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}</li>
                                        {response.map((item) => (
                                            item[headeritem] ?
                                                <li className="li-item-right">{headeritem === "square_feet" ?
                                                    item[headeritem] : item[headeritem]}</li> :
                                                <li className="li-item-right">N/A</li>
                                        ))}
                                    </div>
                                ))}
                                {info.city.map((headeritem) => (
                                    <div className="pad-data-container">
                                        <li className="li-headings">
                                            {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}</li>
                                        {response.map((item) => (
                                            item[headeritem] ?
                                                <li className="li-item-right">
                                                    {capitalizeFirstCharacter(item[headeritem])}
                                                </li> :
                                                <li className="li-item-right">None</li>
                                        ))}
                                    </div>
                                ))}
                                {info.status.map((headeritem) => (
                                    <div className="pad-data-container">
                                        <li className="li-headings">
                                            {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}</li>
                                        {response.map((item) => (
                                            item[headeritem] ?
                                                <li className="li-item-right">{headeritem==="available_date"?
                                                formatDate(item[headeritem]):item[headeritem]}</li> :
                                                <li className="li-item-right">Not updated</li>
                                        ))}
                                    </div>
                                ))}
                                <div className="pad-data-container">
                                    <li className="li-headings">Url</li>
                                    {response.map((item) => (
                                        item["url"] ?
                                            <li className="li-item-right">
                                                <CopyToClipboard text={item["url"]}
                                                    onCopy={() => toast('copied')}>
                                                    <span className="copyProperty">
                                                        <i class="fas fa-copy"></i></span>
                                                </CopyToClipboard>
                                                <a className="linkA" target="_blank" href={item["url"]}>
                                                    Affordable</a>
                                            </li> :
                                            <li className="li-item-right">None</li>
                                    ))}
                                </div>

                                {info.rent.map((headeritem) => (
                                    <div className="pad-data-container">
                                        <li className="li-headings">
                                            {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}</li>
                                        {response.map((item) => (
                                            item[headeritem] ?
                                                <li className="li-item-right">
                                                    {headeritem === "distance_from_subject" ? item[headeritem].toFixed(2) + " Miles" : numberFormat(item[headeritem])}
                                                </li> :
                                                <li className="li-item-right">None</li>
                                        ))}
                                    </div>
                                ))}{info.fees.map((headeritem) => (
                                    <div className="pad-data-container">
                                        <li className="li-headings">
                                            {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}</li>
                                        {response.map((item) => (
                                            item[headeritem] ?
                                                <li className="li-item-right">
                                                    {headeritem === "distance_from_subject" ? item[headeritem].toFixed(2) + " Miles" : numberFormat(item[headeritem])}
                                                </li> :
                                                <li className="li-item-right">Not required</li>
                                        ))}
                                    </div>
                                ))}
                                {info.other.map((headeritem) => (
                                    <div className="pad-data-container">
                                        <li className="li-headings">
                                            {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}</li>
                                        {response.map((item) => (
                                            item[headeritem] ?
                                                <li className="li-item-right">Yes</li> :
                                                <li className="li-item-right">No</li>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div> : <div className="nullPadData">
                            No Data Available
                        </div>) : <Data_Loader />)
                : <></>}
            <ToastContainer />
        </div>
        </>
    )
}
export default Affordable_housing;