import { useState } from "react";
import "./affordable_hud.css"
import Affordable_housing from "./affordable_housing";

const Affordable_Hub = ({huduser_data,affordablehousing_data,subjectRefinedData}) => {
    const [isHudData,setIsHudData]=useState(false)
    const [isAffHouse,setIsAffHouse]=useState(false)
    const ExportNumberFormat = (input) => {
        if (input) {
            if (input[0] === "$") {
                return input
            } else {
                const output = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(input);
                return output
            }

        } else {
            return "None"
        }

    }
    const hudDataHandler=()=>{
        if(isHudData){
            setIsHudData(false)
        }else{
            setIsHudData(true)
        }
    }
    const affHouseHandler=()=>{
        if(isAffHouse){
            setIsAffHouse(false)
        }else{
            setIsAffHouse(true)
        }
    }
    return (
        <>
            <div className="bottom-div">

                <div className='affordable-Div'>
                    <div className="left-data-container">
                        {isHudData === true ? <><div className="tabDiv collapsed-Div" onClick={hudDataHandler}>
                            <li className="allTabs">HUD User</li><i class="fas fa-angle-up"></i></div></> :
                            <>
                                <li className={`bottomAllTabs tabDiv openTab`} onClick={hudDataHandler}>
                                    HUD User<i class="fas fa-angle-down"></i></li>

                                {huduser_data === "None" || huduser_data === null ?
                                    <div className='noData'>Sorry! no data is available</div> :
                                    <div className="flexable-Div">
                                        <div className='li-Div'>
                                            <li className='li-item'>Efficiency</li>
                                            <li className='li-item'>1 Bedroom</li>
                                            <li className='li-item'>2 Bedroom</li>
                                            <li className='li-item'>3 Bedroom</li>
                                            <li className='li-item'>4 Bedroom</li>
                                            <li className='li-item'>2 Bedroom FMR Basis</li>
                                        </div>
                                        <div className='li-Div'>
                                            <li className='li-item'>{huduser_data.Efficiency ? ExportNumberFormat(huduser_data.Efficiency) : "None"}</li>
                                            <li className='li-item'>{huduser_data["One-Bedroom"] ? ExportNumberFormat(huduser_data["One-Bedroom"]) : "None"}</li>
                                            <li className='li-item'>{huduser_data["Two-Bedroom"] ? ExportNumberFormat(huduser_data["Two-Bedroom"]) : "None"}</li>
                                            <li className='li-item'>{huduser_data["Three-Bedroom"] ? ExportNumberFormat(huduser_data["Three-Bedroom"]) : "None"}</li>
                                            <li className='li-item'>{huduser_data["Four-Bedroom"] ? ExportNumberFormat(huduser_data["Four-Bedroom"]) : "None"}</li>
                                            <li className='li-item'>{huduser_data["Two-Bedroom FMR Basis"] ? huduser_data["Two-Bedroom FMR Basis"] : "None"}</li>
                                        </div>
                                    </div>}
                            </>}
                    </div>
                </div>
            </div>
        </>
    )
}
export default Affordable_Hub;