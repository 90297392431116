import "./data-loader.css"
import Spinner from 'react-bootstrap/Spinner';
const Data_Loader = () => {
    return (
        <>
            <div className="data-loader-container">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
                {/* <div class="shadow">
                    <div class="">
                        <div class="item top"></div>
                        <div class="item left"></div>
                        <div class="item right"></div>
                        <div class="item bottom"></div>
                    </div>
                </div>*/}
            </div> 
            
        </>
    )
}
export default Data_Loader;