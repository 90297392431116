import "./rooms.css"
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import { useState } from "react";
// import "./modal.css"
const Rooms = ({ show, handleHideImage, photo, rooms, info, time, desc, host }) => {
    const [pricePer, setPricePer] = useState({})
    console.log(host,info,rooms)
    return (
        <><Modal
            show={show}
            onHide={handleHideImage}
            dialogClassName="modal-90w dialog-modal"
            aria-labelledby="example-custom-modal-styling-title">
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Room Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="" style={{ "width": "100%" }}>
                    <div className="topCard">
                        <div className="descriptionCard" style={{ "width": "120vh" }}>
                            <div className="timing-heading">
                                <i class="fas fa-house-user"></i>
                                Description
                            </div>
                            <div className="divFlex">

                                <div className="roomImgDiv">
                                    <img className="roomImage" src={photo} />
                                </div>
                                <div className="description">{desc}</div>
                            </div>
                        </div>
                        <div className="owner-timings descriptionCard">
                            <div className="timingsDiv">
                                <div className="timing-heading">
                                    <i class="fas fa-user"></i>
                                    Owner
                                </div>
                                <div className="timing">
                                    <div className="days">
                                        <li className="roomsHeadings">Name</li>
                                        <div className="">{host["display_name"] ? host["display_name"] : "None"}</div>
                                    </div>
                                    <div className="days">
                                        <li className="roomsHeadings">Host id</li>
                                        <div className="">{host["host_id"] ? host["host_id"] : "None"}</div>
                                    </div>
                                </div>
                                <div className="timing">
                                    <div className="days">
                                        <li className="roomsHeadings">Phone</li>
                                        <div className="">{host["phone"] ? host["phone"] : "None"}</div>
                                    </div>
                                    <div className="days">
                                        <li className="roomsHeadings">Company name</li>
                                        <div className="">{host["company_name"] ? host["company_name"] : "None"}</div>
                                    </div>
                                </div>
                                <div className="timing">
                                    <div className="days">
                                        <li className="roomsHeadings">Is owner</li>
                                        <div className="">{host["is_owner"] ? host["is_owner"] : "None"}</div>
                                    </div>
                                </div>
                            </div>
                            <span className="vertical-line"></span>
                            {time ? <div className="timingsDiv">
                                <div className="timing-heading">
                                    <i class="fas fa-calendar-alt"></i>
                                    Move-in times
                                </div>
                                <div className="timing">
                                    <div className="days">
                                        <li className="roomsHeadings">Monday-Friday</li>
                                        <div className="">{time["weekdays"] ? time["weekdays"]["from_time"] : "None"}
                                            - {time["weekdays"] ? time["weekdays"]["to_time"] : "None"}</div>
                                    </div>
                                    <div className="days">
                                        <li className="roomsHeadings">Saturday-Sunday</li>
                                        <div className="">{time["weekends"] ? time["weekends"]["from_time"] : "None"}
                                            - {time["weekends"] ? time["weekends"]["to_time"] : "None"}</div>
                                    </div>

                                </div>
                            </div> : <></>
                            }
                        </div>
                    </div>

                    <div className="roomDetailsTable">
                        <div className="timingsDiv">
                            <div className="timing-heading">
                                <i class="fas fa-bed"></i>
                                Room Details
                            </div>
                        </div>
                        {<div className="right-data">
                            {info.roomDetails.map((item) => (
                                <div className="pad-data-container">
                                    <li className='li-headings'>{(item.charAt(0).toUpperCase() + item.slice(1)).replaceAll("_", " ")}</li>

                                    {rooms.map((obj, i) => (
                                        (rooms[i][item] ?
                                            (item === "base_price" || item === "recommended_price" ||
                                                item === "move_in_fee" || item === "prices_for_commitment" ?
                                                <li className={`li-item-right`}>{`$${rooms[i][item]}`}</li> :
                                                <li className={`li-item-right`}>{rooms[i][item]}</li>) :
                                            <li className={`li-item-right`}>None</li>)

                                    ))}
                                </div>
                            ))}
                            {info.price.map((item) => (
                                <div className="pad-data-container">
                                    <li className={`li-headings`}>{`Price for ${(item.charAt(0).toUpperCase() + item.slice(1)).replaceAll("_", " ")}`}</li>
                                    {rooms.map((obj, i) => (
                                        (rooms[i]["prices_for_commitment"][item] ?
                                            (<li className={`li-item-right`}>${rooms[i]["prices_for_commitment"][item]}</li>) :
                                            <li className={`li-item-right`}>None</li>)

                                    ))}
                                </div>
                            ))}
                        </div>}
                        {/* <div className="right-data">
                            <div className="pad-data-container">
                                {info.roomDetails.map((item) => (
                                    <li className='li-item'>{(item.charAt(0).toUpperCase() + item.slice(1)).replaceAll("_", " ")}</li>
                                ))}
                                {info.price.map((item) => (
                                    <li className={`li-item-right`}>{`Price for ${(item.charAt(0).toUpperCase() + item.slice(1)).replaceAll("_", " ")}`}</li>
                                ))}
                            </div>
                            {rooms.map((item, i) => (
                                <div className="pad-data-container" >
                                    {info.roomDetails.map((items) => (
                                        (rooms[i][items] ?
                                            (items === "base_price" || items === "recommended_price" ||
                                                items === "move_in_fee" || items === "prices_for_commitment" ?
                                                <li className={`li-item-right`}>{`$${rooms[i][items]}`}</li> :
                                                <li className={`li-item-right`}>{rooms[i][items]}</li>) :
                                            <li className={`li-item-right`}>None</li>)
                                    ))}
                                    {info.price.map((item) => (
                                        (rooms[i]["prices_for_commitment"][item] ?
                                            <li className={`li-item-right`}>{`$${rooms[i]["prices_for_commitment"][item]}`}</li> : "")
                                    ))}
                                </div>
                            ))}

                        </div> */}

                    </div>

                </div>
            </Modal.Body>
        </Modal>


        </>
    )
}
export default Rooms;