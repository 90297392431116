import "./newPadsplit.css"
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-toastify/dist/ReactToastify.css';
import Rooms from "../room details/rooms";
import Data_Loader from "../data-loader/data-loader";
const NewPadSplit = ({ address, latitude, longitude, city, state, zip }) => {
    const [host, setHost] = useState({})
    const [moveInTime, setMoveInTime] = useState([])
    const [description, setdescription] = useState("")
    const [isPadsplit, setIsPadsplit] = useState(true)
    const [roomsData, setRoomsData] = useState([])
    const [response, setResponse] = useState([])
    const [showImage, setShowImage] = useState(false)
    const [photo, setPhoto] = useState("")
    const [show, setShow] = useState(false)
    const [rooms, setRooms] = useState(0)
    const [avgPrice, setAvgPrice] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const data = () => {
        const headers = {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token")
            }
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}padsplit`, {
            "city": city,
            "state": state,
            "zip": zip,
            "subject_property": {
                "map_coordinates": {
                    "address": address,
                    "latitude": latitude,
                    "longitude": longitude
                }
            }
        }, headers).then(async (res) => {
            setResponse(res.data.rentals)
            setShow(true)
            setAvgPrice(res.data.average_price)
            setRooms(res.data.rooms)
            setIsLoading(false)
            if (res.data.rentals !== null) {
                await axios.post(`${process.env.REACT_APP_BASE_URL}store_padsplit`, {
                    "data": res.data.rentals
                }, headers).then((res) => {
                    console.log(res)
                }).catch((e) => {
                    toast.error("failed to store padsplit data")
                })
            }
        }).catch((e) => {
            toast.error("failed to load padsplit data")
            setIsLoading(false)
        })
    }
    const handleShowImage = (photo, rooms, time, description, host) => {
        setRoomsData(rooms)
        setShowImage(true)
        setPhoto(photo)
        setMoveInTime(time)
        setdescription(description)
        setHost(host)
    }
    const handleHideImage = () => {
        setShowImage(false)
        setPhoto("null")
    }
    const handlePadsplit = () => {
        if (isPadsplit === true) {
            setIsPadsplit(false)
        } else {
            setIsPadsplit(true)
        }
    }
    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();

        return `${month}-${day}-${year}`;
    }
    const info = {
        basicInfo: ["bedrooms", "bathrooms",
            //  "move_in_timings"
        ],
        parkingDetails: ['street_parking', 'garage_parking',
            'premises_parking', 'available_parking_spots', 'total_parking_spots'],
        rentalDetails: ['rental_id', 'rental_name', 'rental_view_count'],
        status: ['last_status_update', 'listing_status'],
        links: ['url', 'tour_url'],
        city: [
            // 'line', 'city', 'state', 'zip', 
            'neighborhood'],
        roomDetails: ["room_id", "room_number", "room_name", "base_price", "recommended_price",
            "move_in_fee", "square_feet", "listing_status", "room_size", "bed_size",],
        price: ["fiveWeeks", "twelveWeeks", "twentyFourWeeks"]
    }
    const headers = ["address", "rental_type"]
    useEffect(() => {
        data()
    }, [])
    return (
        <div>
            {isPadsplit ? <>
                {response !== null ?
                    <div className="padsplit-div" onClick={handlePadsplit}><li className="padsplit-heading">{`Padsplit Data (Average room price is $${avgPrice.toFixed(2)})`}</li>
                        <i class="fas fa-angle-up"></i>
                    </div>
                    :
                    <div className="padsplit-div" onClick={handlePadsplit}><li className="padsplit-heading">{`Padsplit Data`}</li>
                        <i class="fas fa-angle-up"></i>
                    </div>}

                <Rooms show={showImage} handleHideImage={handleHideImage} photo={photo} rooms={roomsData} info={info} time={moveInTime} desc={description} host={host} />
                {
                    !isLoading ?
                        (response !== null ?
                            <div className="data-container" id="scroll-bar">
                                <div className="left-data">
                                    {headers.map((headeritem) => (
                                        <div className="pad-data-container">
                                            <li className="li-headings">
                                                {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}</li>
                                            {response.map((item, index) => (
                                                (item[headeritem] ? <>
                                                    <li className="li-item-right">{
                                                    headeritem==="rental_type"?
                                                    item[headeritem].charAt(0).toUpperCase() + item[headeritem].slice(1):item[headeritem]}</li></> :
                                                    <><li className="li-item-right">None</li></>) 
                                            ))}
                                        </div>

                                    ))}
                                    <div className="pad-data-container">
                                        <li className="li-headings">Description</li>
                                        {response.map((item, index) => (
                                            <li className="li-item-right"><span className="imageIcon"
                                                onClick={() => handleShowImage(item["display_image_url"], item["rooms"], item["move_in_timings"], item["rental_description"], item["host"])}>
                                                <i class="fas fa-info-circle"></i></span></li>)
                                        )}
                                    </div>
                                </div>
                                <div className="right-data">


                                    {info.basicInfo.map((headeritem) => (
                                        <div className="pad-data-container">
                                            <li className="li-headings">{(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}
                                            </li>
                                            {response.map((item, index) => (
                                                index === 0 ? (item[headeritem] ? <>
                                                    <li className="li-item-right">{item[headeritem]}</li></> :
                                                    <>
                                                        <li className="li-item-right">None</li></>) :
                                                    (item[headeritem] ?
                                                        <li className="li-item-right">{item[headeritem]}</li> : <li className="li-item-right">None</li>)
                                            ))}

                                        </div>
                                    ))}
                                    {info.city.map((headeritem) => (
                                        <div className="pad-data-container">
                                            <li className="li-headings">
                                                {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}</li>
                                            {response.map((item, index) => (
                                                index === 0 ? (item[headeritem] ? <>
                                                    <li className="li-item-right">{item[headeritem]}</li></> :
                                                    <>
                                                        <li className="li-item-right">None</li></>) :
                                                    (item[headeritem] ?
                                                        <li className="li-item-right">{item[headeritem]}</li> : <li className="li-item-right">None</li>)
                                            ))}

                                        </div>
                                    ))}
                                    <div className="pad-data-container">
                                        <li className="li-headings">Distance from subject</li>
                                        {response.map((item, index) => (
                                            <li className="li-item-right">{item["distance_from_subject"].toFixed(2) + " Miles"}</li>
                                        ))}
                                    </div>
                                    {info.status.map((headeritem) => (
                                        <div className="pad-data-container">
                                            <li className="li-headings">
                                                {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}</li>
                                            {response.map((item, index) => (
                                                item[headeritem] ? <>
                                                    <li className="li-item-right">
                                                        {headeritem === "last_status_update" ? formatDate(item[headeritem]) :
                                                         (item[headeritem].charAt(0).toUpperCase() + item[headeritem].slice(1))}</li></> :
                                                        <li className="li-item-right">{headeritem === "last_status_update" ? "Not updated" : "None"}</li>
                                            ))}

                                        </div>
                                    ))}
                                    {info.rentalDetails.map((headeritem) => (
                                        <div className="pad-data-container">
                                            <li className="li-headings">
                                                {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}</li>
                                            {response.map((item, index) => (
                                                index === 0 ? (item[headeritem] ? <>
                                                    <li className={headeritem === "rental_name" ? "li-item-right heading-ellipsis" : `li-item-right`}>{item[headeritem]}</li></> :
                                                    <>
                                                        <li className="li-item-right">None</li></>) :
                                                    (item[headeritem] ?
                                                        <li className={headeritem === "rental_name" ? "li-item-right heading-ellipsis" : `li-item-right`}>
                                                            {item[headeritem]}</li>
                                                        : <li className="li-item-right">None</li>)
                                            ))}

                                        </div>
                                    ))}

                                    {info.links.map((headeritem) => (
                                        <div className="pad-data-container">
                                            <li className="li-headings">
                                                {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}</li>
                                            {response.map((item, index) => (
                                                (item[headeritem] ?
                                                    <li className="li-item-right">
                                                        <CopyToClipboard text={item[headeritem]}
                                                            onCopy={() => toast('copied')}>
                                                            <span className="copyProperty">
                                                                <i class="fas fa-copy"></i></span>
                                                        </CopyToClipboard>
                                                        <a className="linkA" target="_blank" href={item[headeritem]}>

                                                            {headeritem === "url" ? "Padsplit" : (headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_link", " ")}</a>
                                                    </li> :
                                                    <li className="li-item-right">N/A</li>)
                                            ))}

                                        </div>
                                    ))}
                                    {info.parkingDetails.map((headeritem) => (
                                        <div className="pad-data-container">
                                            <li className="li-headings">
                                                {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}
                                            </li>
                                            {response.map((item, index) => (
                                                item[headeritem] ?
                                                    (headeritem === "distance_from_subject" ?
                                                        <li className="li-item-right">{(item[headeritem]).toFixed(2)}</li> :
                                                        <li className="li-item-right">{item[headeritem]}</li>) :
                                                    <li className="li-item-right">No parking</li>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div> :
                            <div className="nullPadData">
                                No Data Available
                            </div>) : <Data_Loader />
                }
            </> :
                response !== null ?
                    <div className="padsplit-div" onClick={handlePadsplit}><li className="padsplit-heading">{`Padsplit Data (Average room price is $${avgPrice.toFixed(2)})`}</li>
                        <i class="fas fa-angle-up"></i>
                    </div>
                    :
                    <div className="padsplit-div" onClick={handlePadsplit}><li className="padsplit-heading">{`Padsplit Data`}</li>
                        <i class="fas fa-angle-up"></i>
                    </div>
            }
            <ToastContainer />
        </div>
    )
}
export default NewPadSplit;