import { useState ,useEffect} from "react";
import axios from "axios"
import "./login.css"
import { useNavigate } from "react-router-dom";
import logo from "../img/logo.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const navigate = useNavigate();
    const [user,setUser]=useState("")
    const [password,setPassword]=useState("")
    const getLogin=()=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}login`,{
            "email": user,
            "password": password,

        }).then((res)=>{
            if (res.data.status === 200){
                console.log(res)
                sessionStorage.setItem("token",res.data.data.JWT_Token)
                sessionStorage.setItem("email",res.data.data.email)
                sessionStorage.setItem("isAuthenticated",false)
                navigate(`/dashboard`);
            }
            else{
                toast.error(res.data.error)
            }
            
        }).catch((e)=>{
            toast.error("Invalid credentials")
        })
    }
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                getLogin()
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [user,password, navigate]);
    useEffect(()=>{
        if(sessionStorage.getItem("isSignedIn")==="true"){
            toast.success("Signed in successfully.")
            sessionStorage.setItem("isSignIn",false)
        }
    },[])
    return (
        <><div className="login-main">
            
            <section>
                <div className="login-form" action="login-box">
                    <div className='login-logo'><img className="login-img" src={logo} alt='logo' /></div>
                    <label className="login-label" >Email</label>
                    <input className="login-input"
                        onChange={(event) => setUser(event.target.value)}

                    />
                    <label className="login-label" >Password</label>
                    <input className="login-input" type="password"
                        onChange={(event) => setPassword(event.target.value)} />
                    <button class="login-button" onClick={getLogin}>login</button>
                    <div className="login-div">
                        
                        <a className="login-anchor" onClick={()=>navigate('/signup')}>sign up</a>
                    </div>
                </div>

            </section>

        </div>

    <ToastContainer/>
        </>
    )
}
export default Login;