import { useRef, useEffect, useState } from "react";
import logo from './img/logo.png'
import { useNavigate, useLocation } from "react-router-dom"
import "./navbar/navbar.css"
import Button from 'react-bootstrap/Button';
const Header = ({ state, updateState, callHandler, paramValues, exportReports }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [query, setQuery] = useState("");
    const [isFloating, setIsFloating] = useState(false);
    const floatingIconRef = useRef(null);
    const update = (state, value) => {
        updateState(state, value)
    }
    const apiCall = () => {
        callHandler()
        setQuery("")
    }
    const download = (param1, param2) => {
        exportReports(param1, param2)
    }

    const routeToIndex = (event) => {
        if (event.ctrlKey) {
            window.open(location.pathname.split('/')[2] + "/dashboard");
        } else {
            navigate(`/dashboard`)
        }
    }

    const keyPressed = (event) => {
        if (event.key === "Enter" && event.target.value) {
            apiCall()
        }
    }
    const autoCompleteRef = useRef(null);

    let autoComplete;

    const loadScript = (url, callback) => {
        let script = document.createElement("script");
        script.type = "text/javascript";

        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            script.onload = () => callback();
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    };
    const options = {
        componentRestrictions: { country: "usa" },
        types: ["address"]
    };
    const handleScriptLoad = (updateQuery, autoCompleteRef) => {
        autoComplete = new window.google.maps.places.Autocomplete(
            autoCompleteRef.current,
            options
        );
        autoComplete.setFields(["address_components", "formatted_address"]);
        autoComplete.addListener("place_changed", () =>
            handlePlaceSelect(updateQuery)
        );
    }

    const handlePlaceSelect = async (updateQuery) => {
        const addressObject = autoComplete.getPlace();
        const query = addressObject.formatted_address;
        updateQuery(query);
        console.log(addressObject);
        if (query.slice(-3) === "USA" || query.slice(-3) === "usa") {
            apiCall()
        }
    }
    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
        );
    }, []);

    useEffect(() => {
        const handleScroll = async () => {
            const container = document.querySelector('.dummy');
            const containerRect = container.getBoundingClientRect();
            const isContainerVisible = containerRect.bottom <= window.innerHeight;

            setIsFloating(isContainerVisible);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const clearSession = () => {
        sessionStorage.clear()
        navigate('/')
    }
    useEffect(() => {
        const floatingIcon = floatingIconRef.current;

        if (floatingIcon) {
            if (isFloating) {
                floatingIcon.style.position = 'fixed';
                floatingIcon.style.bottom = '20px';
                floatingIcon.style.right = '20px';
            } else {
                floatingIcon.style.position = 'absolute';
                floatingIcon.style.bottom = '20px';
                floatingIcon.style.right = '20px';
            }
        }
    }, [isFloating]);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                if (query) {
                    apiCall()
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [query, navigate]);
    return (
        <>
            <div className="head">

                <div className='image-Div' onClick={routeToIndex}>
                    <img className="logo" src={logo} alt='logo' /></div>
                <div className="container-space">
                    <div className="d-flex align-items-center">
                        <div className="search-container-header">
                            <input
                                className="search-input-header"
                                ref={autoCompleteRef}
                                onKeyDown={keyPressed}
                                placeholder={state.placeholder}
                                value={query}
                                onChange={(e) => {
                                    if (state.fieldCount === 0) {
                                        setQuery(e.target.value)
                                        update("subjectProperty", e.target.value)
                                        update("text", e.target.value)
                                    } else {
                                        setQuery(e.target.value)
                                        update("text", e.target.value)
                                    }

                                }}
                            />

                            <i class="fas fa-search searchIcon" onClick={(event) => { apiCall() }}></i>
                        </div>
                        <div className={`${isFloating ? 'floating-icon' : 'disable'}`} >
                            <div className="download-Div">
                                <div class="dropdown">
                                    <button class="dropbtn"><i class="fas fa-download"></i></button>
                                    <div class="bottom-dropDown">
                                        <a onClick={() => {
                                            download(paramValues.excelType, paramValues.excel)
                                        }}>Excel</a>
                                        <a onClick={() => {
                                            download(paramValues.csvType, paramValues.csv)
                                        }}>CSV</a>
                                        <a >PDF</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="download-Div">
                            <div class="dropdown">
                                <button class="dropbtn"><i class="fas fa-download"></i></button>
                                <div class="dropdown-content">
                                    <a onClick={() => {
                                        download(paramValues.excelType, paramValues.excel)
                                    }}>Excel</a>
                                    <a onClick={() => {
                                        download(paramValues.csvType, paramValues.csv)
                                    }}>CSV</a>
                                    <a >PDF</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile-Div">
                        <div class="dropdown">
                            <button class="dropbtn">

                                <i class="fas fa-user-circle" style={{ "font-size": "4.5rem", }}>
                                </i>
                            </button>
                            <div class="dropdown-content px-3">
                                <li className="email fw-medium ">{sessionStorage.getItem("email")}</li>
                                <div className="d-flex justify-content-center mb-2"><Button className="w-100" variant="secondary" onClick={clearSession}>Logout</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Header;