import Home from "../home/Home"
import {useNavigate} from "react-router-dom"
const Main=(props)=>{

    const navigation = useNavigate();
    return(
        <>
            <Home {...props} navigation={navigation} />
        </>
    )
}
export default Main;