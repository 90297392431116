import { lazy, useEffect, useState } from "react"
import "./reference_properties.css"
import Data_Loader from "../data-loader/data-loader"
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
const Reference_Properties = ({ referenceProps }) => {
    const [show, setShow] = useState(true)
    const [lazyData, setLazyData] = useState("")
    const [isLazy,setIsLazy]=useState(true)
    const info = {
        basicInfo: ["beds", "baths", "square_feet", "lot_square_feet", "year_built", "story",
            "garage_type"],
        divisions: ["neighborhood", "subdivision", "county"],
        prices: ["zillow", "realtor", "redfin", "price", "median_price",
            // "estimated_rent", 
            // "adjusted_sales_price",
        ],
        diffPrice: ["price_per_square_feet", "square_feet_difference",],
        status: ['date_sold', "listing_status"],
        links: ['redfin_link', 'zillow_link', "realtor_link",],
        scores: ["comp_score"]
    }
    const lazyLoading = (addressArray) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}lazy_loading`, { addressArray }).then((res) => {
            var lazyDataArray = []
            for (let i = 0; i < res.data.length; i++) {
                if (res.data[i]["subject_property"]) {
                    lazyDataArray.push(res.data[i]["subject_property"])
                }

            }
            setLazyData(lazyDataArray)
            setIsLazy(false)
        })
        console.log("lazy loading is working")
    }
    const handle_reference_tab = () => {
        if (show === true) {
            setShow(false)
        } else {
            setShow(true)
        }
    }
    const validateLinks = (linkType, link) => {
        if (link.startsWith("https://") || link.startsWith("http://")) {
            return link
        } else {
            if (linkType == "redfin_link") {
                var return_link = "https://redfin.com" + link
                return return_link
            } else if (linkType == "zillow_link") {
                var return_link = "https://zillow.com" + link
                return return_link
            } else {
                var return_link = "https://realtor.com" + link
                return return_link
            }
        }
    }
    const numberFormat = (input) => {
        if (input) {
            if (input[0] === "$") {
                return input
            } else {
                const output = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(input);
                return output
            }

        } else {
            return "None"
        }

    }
    useEffect(() => {
        if (referenceProps) {
            var addressArray = []
            for (let i = 0; i < referenceProps.length; i++) {
                addressArray.push(referenceProps[i]["address"])
            }
            lazyLoading(addressArray)
        }
    }, [referenceProps])
    return (
        <>
            {show ?
                <div className="padsplit-div" onClick={handle_reference_tab}><li className="padsplit-heading">Reference Properties</li>
                    {/* {isLazy?<div className="w-10 h-10">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>:<></>} */}

                    <i class="fas fa-angle-down"></i>
                </div>
                :
                <div className="padsplit-div" onClick={handle_reference_tab}><li className="padsplit-heading">Reference Properties</li>
                    <i class="fas fa-angle-up"></i>
                </div>}
            {referenceProps ?
                (show ?
                    <div className="ref-container" id="scroll-bar">
                        <div className="left-data">
                            <div className="pad-data-container">
                                <li className="li-headings">Address</li>
                                {referenceProps.map((item, index) => (
                                    <li className="li-item-right">{item.address}</li>)
                                )}
                            </div>
                        </div>
                        <div className="right-data">
                            {info.basicInfo.map((headeritem) => (
                                <div className="pad-data-container">
                                    <li className="li-headings">{headeritem === "lot_square_feet" ? "Lot SqFt" :
                                        (headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}
                                    </li>
                                    {referenceProps.map((item, index) => (
                                        (item[headeritem] ? <>
                                            <li className="li-item-right">{item[headeritem]}</li></> :
                                            lazyData ?
                                                <li className="li-item-right">
                                                    {lazyData[index]?.[headeritem] ?
                                                        lazyData[index]?.[headeritem] : "N/A"}
                                                </li> :
                                                <li className="li-item-right"><i class="fas fa-hourglass-start"></i></li>
                                        )
                                    ))}

                                </div>
                            ))}
                            {info.divisions.map((headeritem) => (
                                <div className="pad-data-container">
                                    <li className="li-headings">{(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}
                                    </li>
                                    {referenceProps.map((item, index) => (
                                        (item[headeritem] ? <>
                                            <li className="li-item-right">{item[headeritem]}</li></> :
                                            lazyData ?
                                                <li className="li-item-right">
                                                    {lazyData[index]?.[headeritem] ?
                                                        lazyData[index]?.[headeritem] : "N/A"}
                                                </li> :
                                                <li className="li-item-right"><i class="fas fa-hourglass-start"></i></li>
                                        )
                                    ))}

                                </div>
                            ))}
                            {info.status.map((headeritem) => (
                                <div className="pad-data-container">
                                    <li className="li-headings">{(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}
                                    </li>
                                    {referenceProps.map((item, index) => (
                                        (item[headeritem] ? <>
                                            <li className="li-item-right">{item[headeritem]}</li></> :
                                            lazyData ?
                                                <li className="li-item-right">
                                                    {lazyData[index]?.[headeritem] ?
                                                        lazyData[index]?.[headeritem] : "N/A"}
                                                </li> :
                                                <li className="li-item-right"><i class="fas fa-hourglass-start"></i></li>)
                                    ))}

                                </div>
                            ))}
                            {info.links.map((headeritem) => (
                                <div className="pad-data-container">
                                    <li className="li-headings">{(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}
                                    </li>
                                    {referenceProps.map((item, index) => (
                                        (item[headeritem] ? <>
                                            <li className="li-item-right Ref-links">
                                                <a className="linkA" target="_blank" href={validateLinks(headeritem, item[headeritem])}>
                                                    {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_link", " ")}
                                                </a>
                                            </li></> :
                                            <li className="li-item-right">{headeritem === "zillow_link" ?
                                                (lazyData ? <a className="linkA" target="_blank" href={lazyData[index]?.["url"]}>
                                                    Zillow</a> : <i class="fas fa-hourglass-start"></i>) : "N/A"}
                                            </li>)

                                    ))}

                                </div>
                            ))}
                            {info.prices.map((headeritem) => (
                                <div className="pad-data-container">
                                    <li className="li-headings">{(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}
                                    </li>
                                    {referenceProps.map((item, index) => (
                                        (item[headeritem] ?
                                            <li className="li-item-right">{numberFormat(item[headeritem])}</li>
                                            :
                                            <li className="li-item-right">
                                                {headeritem === "zillow" ?
                                                    (lazyData[index]?.zestimate ? numberFormat(lazyData[index]?.zestimate) : "N/A") : "N/A"}
                                            </li>)
                                    ))}

                                </div>
                            ))}
                            <div className="pad-data-container">
                                <li className="li-headings">Estimated rent</li>
                                {referenceProps.map((item, index) => (
                                    (item["estimated_rent"] ?
                                        <li className="li-item-right">{numberFormat(item["estimated_rent"])}</li>
                                        :
                                        lazyData ?
                                            <li className="li-item-right">
                                                {lazyData[index]?.["rent_zestimate"] ?
                                                    numberFormat(lazyData[index]?.["rent_zestimate"]) : "N/A"}
                                            </li> :
                                            <li className="li-item-right"><i class="fas fa-hourglass-start"></i></li>)
                                ))}
                            </div>
                            {info.diffPrice.map((headeritem) => (
                                <div className="pad-data-container">
                                    <li className="li-headings">{(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}
                                    </li>
                                    {referenceProps.map((item, index) => (
                                        (item[headeritem] ?
                                            <li className="li-item-right">{numberFormat(item[headeritem])}</li>
                                            :
                                            lazyData ?
                                                <li className="li-item-right">
                                                    {lazyData[index]?.[headeritem] ?
                                                        lazyData[index]?.[headeritem] : "N/A"}
                                                </li> :
                                                <li className="li-item-right"><i class="fas fa-hourglass-start"></i></li>)
                                    ))}

                                </div>
                            ))}
                            {<div className="pad-data-container">
                                <li className="li-headings">Reference point</li>
                                {referenceProps.map((item, index) => (
                                    <li className="li-item-right">{item["reference_point"].toString()}</li>
                                ))}
                            </div>}
                            {info.scores.map((headeritem) => (
                                <div className="pad-data-container">
                                    <li className="li-headings">{(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}
                                    </li>
                                    {referenceProps.map((item, index) => (

                                        <li className="li-item-right">{item[headeritem].toFixed(2)}</li>))}

                                </div>
                            ))}
                        </div>
                    </div>
                    :
                    <></>) :
                <Data_Loader />
            }

        </>
    )
}
export default Reference_Properties;