import { useRef, useEffect, useState } from "react";
import logo from "./img/logo.png";
import { useNavigate } from "react-router-dom";
import NavBar from "./navbar/navbar";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Page = () => {
    const [query, setQuery] = useState("");
    const navigate = useNavigate();
    const autoCompleteRef = useRef(null);
    let autoComplete;
    const predictions = async (query) => {
        const autocompleteService = new window.google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions(
            {
                input: query,
                types: ["address"],
                componentRestrictions: { country: "usa" },
            },
            (predictions, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions && predictions.length > 0) {
                    const firstPrediction = predictions[0];
                    const placeService = new window.google.maps.places.PlacesService(document.createElement("div"));
                    placeService.getDetails(
                        { placeId: firstPrediction.place_id, fields: ["formatted_address", "address_components"] },
                        (place, status) => {
                            if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
                                const postalCodeComponent = place.address_components.find(
                                    (component) => component.types.includes("postal_code")
                                );
                                const postalCode = postalCodeComponent ? postalCodeComponent.long_name : null;
                                console.log("Postal Code:", postalCode);
                                const fullAddress = place.formatted_address;
                                console.log("Full Address:", fullAddress);
                                navigate(`/search/?q=${fullAddress}`)
                            }
                        }
                    );
                } else {
                    console.log("No predictions available or an error occurred.");
                }
            });
    }

    const loadScript = (url, callback) => {
        let script = document.createElement("script");
        script.type = "text/javascript";
        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            script.onload = () => callback();
        }
        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    };

    const options = {
        componentRestrictions: { country: "usa" },
        types: []
    };

    const handleScriptLoad = (updateQuery, autoCompleteRef) => {
        autoComplete = new window.google.maps.places.Autocomplete(
            autoCompleteRef.current,
            options);
        autoComplete.setFields(["address_components", "formatted_address"]);
        autoComplete.addListener("place_changed", () =>
            handlePlaceSelect(updateQuery));
    }
    const handlePlaceSelect = async (updateQuery) => {
        const addressObject = autoComplete.getPlace();
        const query = addressObject.formatted_address;
        predictions(query)
    }
    const authentication = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}`, {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token")
            }
        }).then((res) => {
            if (sessionStorage.getItem("isAuthenticated") !== "true") {
                toast.success("login successfully")
                sessionStorage.setItem("email",res.data.email)
                sessionStorage.setItem("isAuthenticated", true)
            }
        }).catch((e) => {
            sessionStorage.setItem("isAuthenticated", false)
            navigate(`/`)
        })
    }
    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
        );
    }, []);
    useEffect(() => {
        if (sessionStorage.getItem("isAuthenticated") !== "true") {
            authentication()
        }
    }, [])
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                if (event.target.value) {
                    predictions(event.target.value)
                } else {
                    predictions(query)
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [query, navigate]);

    return (
        <>{sessionStorage.getItem("token") ?
            <>
                <NavBar />
                <div className="mainHome">
                    <div className='homeImgDiv'><img className="logo" src={logo} alt='logo' /></div>
                    <div className="homeSearchBox">
                        <input
                            className="search-input-header"
                            ref={autoCompleteRef}
                            onChange={(event) => setQuery(event.target.value)}
                            placeholder="Search Property"
                            value={query}
                        />
                        <i className="fas fa-search searchIcon" onClick={() => predictions(query)}></i>
                    </div>
                </div>
                <ToastContainer />
            </> :
            <></>
        }
        </>
    );
};

export default Page;