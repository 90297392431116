import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Data_Loader from "../data-loader/data-loader";
const Rental_Comps = ({ property }) => {
    const [response, setResponse] = useState("")
    const [show, setShow] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [lazyData, setLazyData] = useState("")
    const info = {
        basicInfo: ["beds", "baths", "square_feet", "lot_square_feet", "year_built", "story",
            "garage_type"],
        divisions: ["neighborhood", "subdivision", "county",],
        prices: ["zillow", "realtor", "redfin", "price",
            "median_price",
            // "estimated_rent",
            //  "adjusted_sales_price",
        ],
        diffPrices: ["price_per_square_feet", "square_feet_difference",],
        status: ['date_sold', "listing_status"],
        links: ['redfin_link', 'zillow_link', "realtor_link",],
        scores: ["comp_score"]
    }

    const data = () => {
        const headers = {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token")
            }
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}rental_comps`, {
            "is_subject": "True",
            "properties": ["681 Ira St SW, Atlanta, GA 30310"],
            "auto_comp": "True"
        }).then(async (res) => {

            setResponse(res.data)
            if (res.data.subject_property) {
                setIsLoading(false)
            }
            // await axios.post(`${process.env.REACT_APP_BASE_URL}store_padsplit`, {
            //     "data": res.data.rentals
            // }, headers).then((res) => {
            //     // console.log(res)
            // }).catch((e) => {
            //     // console.log(e)
            //     toast.error("failed to store padsplit data")
            // })
        }).catch((e) => {
            toast.error("failed to load padsplit data")
            setIsLoading(false)
        })
    }
    const lazyLoading = (addressArray) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}lazy_loading`, { addressArray }).then((res) => {
            var lazyDataArray = []
            for (let i = 0; i < res.data.length; i++) {
                if (res.data[i]["subject_property"]) {
                    lazyDataArray.push(res.data[i]["subject_property"])
                }
            }
            console.log(lazyDataArray)
            setLazyData(lazyDataArray)
        })
        console.log("lazy loading is working")
    }

    const handle_rental_tab = () => {
        if (show === true) {
            setShow(false)
        } else {
            setShow(true)
        }
    }
    const ExportNumberFormat = (input) => {
        if (input) {
            if (input[0] === "$") {
                return input
            } else {
                const output = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(input);
                return output
            }

        } else {
            return "None"
        }

    }
    useEffect(() => {
        if (response) {
            var addressArray = []

            Object.keys(response.subject_property).map((item) => {
                addressArray.push(item)
            })
            Object.keys(response.comp_properties).map((item) => {
                addressArray.push(item)
            })
            lazyLoading(addressArray)
        }
    }, [response])
    useEffect(() => {
        data()
    }, [])
    return (
        <>{show ?
            <div className="padsplit-div" onClick={handle_rental_tab}><li className="padsplit-heading">Rental Comps</li>
                <i class="fas fa-angle-down"></i>
            </div>
            :
            <div className="padsplit-div" onClick={handle_rental_tab}><li className="padsplit-heading">Rental Comps</li>
                <i class="fas fa-angle-up"></i>
            </div>}
            {show ?
                (!isLoading ?
                    (response.subject_property ?
                        <div className="ref-container" id="scroll-bar">
                            <div className="left-data">
                                <div className="pad-data-container">
                                    <li className="li-headings">Address</li>
                                    <li className="li-item-right">{Object.values(response.subject_property)[0]["address"]}</li>
                                    {Object.keys(response.comp_properties).map((item) => (
                                        <li className="li-item-right">{item}</li>
                                    ))}
                                </div>
                            </div>
                            <div className="right-data">
                                {info.basicInfo.map((headeritem) => (
                                    <div className="pad-data-container">
                                        <li className="li-headings">{headeritem === "lot_square_feet" ? "Lot SqFt" :
                                            (headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}
                                        </li>
                                        <li className="li-item-right">{Object.values(response.subject_property)[0][headeritem] ?
                                            Object.values(response.subject_property)[0][headeritem] : "None"}</li>
                                        {Object.keys(response.comp_properties).map((item, index) => (
                                            <li className="li-item-right">{response.comp_properties[item][headeritem] ?
                                                response.comp_properties[item][headeritem] :
                                                lazyData ?
                                                    lazyData[index + 1]?.[headeritem] ?
                                                        (lazyData[index + 1]?.[headeritem]) : "N/A"
                                                    : <i class="fas fa-hourglass-start"></i>}</li>
                                        ))}
                                    </div>
                                ))}
                                {info.divisions.map((headeritem) => (
                                    <div className="pad-data-container">
                                        <li className="li-headings">{(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}
                                        </li>
                                        <li className="li-item-right">{Object.values(response.subject_property)[0][headeritem] ?
                                            Object.values(response.subject_property)[0][headeritem] : "None"}</li>
                                        {Object.keys(response.comp_properties).map((item, index) => (
                                            <li className="li-item-right">{response.comp_properties[item][headeritem] ?
                                                response.comp_properties[item][headeritem] : lazyData ?
                                                    lazyData[index + 1]?.[headeritem] ?
                                                        (lazyData[index + 1]?.[headeritem]) : "N/A"
                                                    : <i class="fas fa-hourglass-start"></i>}</li>
                                        ))}
                                    </div>
                                ))}
                                <div className="pad-data-container">
                                    <li className="li-headings">Distance from subject</li>
                                    <li className="li-item-right">Subject Property</li>
                                    {Object.keys(response.comp_properties).map((item, index) => (
                                        <li className="li-item-right">{response.comp_properties[item]["travel_info"] ?
                                            Number.parseFloat(response.comp_properties[item]["travel_info"]["distance_from_subject"]).toFixed(2) + " Feet" :
                                            lazyData ?
                                                lazyData[index + 1]?.["distance_from_subject"] ?
                                                    (lazyData[index + 1]?.["distance_from_subject"]) : "N/A"
                                                : <i class="fas fa-hourglass-start"></i>}</li>
                                    ))}
                                </div>
                                {info.prices.map((headeritem) => (
                                    <div className="pad-data-container">
                                        <li className="li-headings">{(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}
                                        </li>
                                        <li className="li-item-right">{Object.values(response.subject_property)[0][headeritem] ?
                                            ExportNumberFormat(Object.values(response.subject_property)[0][headeritem]) : 
                                            lazyData ?
                                                    (lazyData[0]?.['zestimate'] && headeritem === "zillow" ?
                                                    ExportNumberFormat(lazyData[0]?.['zestimate'])
                                                        : "N/A")
                                                    : <i class="fas fa-hourglass-start"></i>}</li>
                                        {Object.keys(response.comp_properties).map((item, index) => (
                                            <li className="li-item-right">{response.comp_properties[item][headeritem] ?
                                                ExportNumberFormat(response.comp_properties[item][headeritem]) :
                                                lazyData ?
                                                    (lazyData[index + 1]?.['zestimate'] && headeritem === "zillow" ?
                                                    ExportNumberFormat(lazyData[index + 1]?.['zestimate'])
                                                        : "N/A")
                                                    : <i class="fas fa-hourglass-start"></i>}</li>
                                        ))}
                                    </div>
                                ))}
                                <div className="pad-data-container">
                                    <li className="li-headings">Estimated rent</li>
                                    <li className="li-item-right">{Object.values(response.subject_property)[0]["rent_estiimate"] ?
                                        ExportNumberFormat(Object.values(response.subject_property)[0]["rent_estiimate"]) : "N/A"}
                                    </li>
                                    {lazyData ? lazyData.map((item, index) => (
                                        <li className="li-item-right">{lazyData[index + 1] ?
                                            ExportNumberFormat(lazyData[index + 1]?.["rent_zestimate"]) : "N/A"}</li>
                                    )) : <li className="li-item-right"><i class="fas fa-hourglass-start"></i></li>}
                                </div>
                                {info.diffPrices.map((headeritem) => (
                                    <div className="pad-data-container">
                                        <li className="li-headings">{(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}
                                        </li>
                                        <li className="li-item-right">{Object.values(response.subject_property)[0][headeritem] ?
                                            ExportNumberFormat(Object.values(response.subject_property)[0][headeritem]) :
                                            lazyData ?
                                                lazyData[0]?.[headeritem] ?
                                                    ExportNumberFormat(lazyData[0]?.[headeritem]) : "N/A"
                                                : <i class="fas fa-hourglass-start"></i>}</li>
                                        {Object.keys(response.comp_properties).map((item, index) => (
                                            <li className="li-item-right">{response.comp_properties[item][headeritem] ?
                                                ExportNumberFormat(response.comp_properties[item][headeritem]) :
                                                lazyData ?
                                                    lazyData[index + 1]?.[headeritem] ?
                                                        ExportNumberFormat(lazyData[index + 1]?.[headeritem]) : "N/A"
                                                    : <i class="fas fa-hourglass-start"></i>
                                            }</li>
                                        ))}
                                    </div>
                                ))}
                                {info.status.map((headeritem) => (
                                    <div className="pad-data-container">
                                        <li className="li-headings">{(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}
                                        </li>
                                        <li className="li-item-right">{Object.values(response.subject_property)[0][headeritem] ?
                                            Object.values(response.subject_property)[0][headeritem] : "None"}</li>
                                        {Object.keys(response.comp_properties).map((item, index) => (
                                            <li className="li-item-right">{response.comp_properties[item][headeritem] ?
                                                response.comp_properties[item][headeritem] :
                                                lazyData ?
                                                    lazyData[index + 1]?.[headeritem] ?
                                                        (lazyData[index + 1]?.[headeritem]) : "N/A"
                                                    : <i class="fas fa-hourglass-start"></i>}</li>
                                        ))}
                                    </div>
                                ))}
                                {info.links.map((headeritem) => (
                                    <div className="pad-data-container">
                                        <li className="li-headings">{(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_", " ")}
                                        </li>
                                        <li className="li-item-right Ref-links">{Object.values(response.subject_property)[0][headeritem] ?
                                            <><a className="linkA" target="_blank" href={Object.values(response.subject_property)[0][headeritem]}>
                                                {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_link", " ")}
                                            </a></> :
                                            lazyData ?
                                                (lazyData[0]?.['url'] && headeritem === "zillow_link" ?
                                                    <a className="linkA" target="_blank" href={lazyData[0]?.['url']}>
                                                        {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_link", " ")}</a>
                                                    : "N/A")
                                                : <i class="fas fa-hourglass-start"></i>
                                        }</li>
                                        {Object.keys(response.comp_properties).map((item, index) => (
                                            <li className="li-item-right Ref-links">{response.comp_properties[item][headeritem] ?
                                                <><a className="linkA" target="_blank" href={response.comp_properties[item][headeritem]}>
                                                    {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_link", " ")}</a></> :
                                                lazyData ?
                                                    (lazyData[index + 1]?.['url'] && headeritem === "zillow_link" ?
                                                        <a className="linkA" target="_blank" href={lazyData[index + 1]?.['url']}>
                                                            {(headeritem.charAt(0).toUpperCase() + headeritem.slice(1)).replaceAll("_link", " ")}</a>
                                                        : "N/A")
                                                    : <i class="fas fa-hourglass-start"></i>
                                            }</li>
                                        ))}
                                    </div>
                                ))}
                                <div className="pad-data-container">
                                    <li className="li-headings">Comp score</li>
                                    <li className="li-item-right">Subject Property</li>
                                    {Object.keys(response.comp_properties).map((item) => (
                                        <li className="li-item-right">{response.comp_properties[item]["comp_score"] ?
                                            (response.comp_properties[item]["comp_score"]).toFixed(2) : "None"}</li>
                                    ))}
                                </div>

                            </div>
                        </div> : <div className="nullPadData">
                            No Data Available
                        </div>) : <Data_Loader />) : <></>
            }
        </>
    )
}
export default Rental_Comps;